import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../config';
import { GetAllServiceResponseItem } from './getAllServicesLinkBooking';

export interface AppointmentServiceCreate {
    appointmentServiceDetails: AppointmentServiceDetailCreate[];
}

interface AppointmentServiceEmployee {
    employeeId?: string;
    timeStartWork?: string;
    timeEndWork?: string;
}

export interface AppointmentServiceDetailCreate {
    serviceId?: string;
    facilityId?: string;
    startTime?: string;
    endTime?: string;
    service?: GetAllServiceResponseItem;
    appointmentServiceEmployees?: AppointmentServiceEmployee[];
}

export interface AppointmentCreateRequest extends HttpRequestData {
    body: {
        readonly id?: string;
        readonly numberOfGuest: number;
        readonly appointmentDate?: string;
        readonly notes?: string;
        readonly branchId?: string;
        readonly customer?: {
            firstName: string;
            lastName: string;
            phone?: string;
            email: string;
        };
        readonly appointmentServices: AppointmentServiceCreate[];
    };
}

export interface AppointmentCreateResponse {
    readonly id: string;
    readonly appointmentDate: string;
    readonly branchId: string;
    readonly appointmentServices: AppointmentServiceCreate[];
}

export const createBooking = httpUtil.createHttpRequestMeta<
    AppointmentCreateRequest,
    AppointmentCreateResponse
>({
    baseUrl: env.apiUrlBase,
    method: 'POST',
    path: '/api/v1/:slug/appointments',
});
