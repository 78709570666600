import React from 'react';

interface LayoutContainerProps {
    children: React.ReactNode;
}

export default function LayoutContainer(props: LayoutContainerProps) {
    return (
        <div className='layout-container'>
            {props.children}
        </div>
    );
}
