import { Text } from '@esg/ui';
import React from 'react';
import './Title.scss';

interface TitleProps {
    translateCode?: string;
    level?: '1' | '2' | '3' | '4' | '5' | '6';
    color?: string;
}

export default function Title(props: TitleProps) {
    const { translateCode, color, level='2' } = props;

    return (
        <Text color={color} translateCode={translateCode} className={`title level-${level}`} />
    );
}
