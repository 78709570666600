import React, { useMemo } from 'react';
import { Block, FlexBox, Spacer, Text, useLocalesFormat } from '@esg/ui';
import { Card, Checkbox } from 'antd';
import { GetAllServiceResponseItem } from '@esg/business-link-booking';
import { useTranslation } from 'react-i18next';
import { JOB_STATUS } from '@esg/shared';
import { AppointmentEmployeeField } from '../fields/AppointmentEmployeeField';
import { translateCodes } from '@/locales';

interface MenuServiceItemProps {
    readonly service: GetAllServiceResponseItem;
    readonly checkedService: boolean;
    readonly onChangeEmployee: (value: string) => void;
    readonly handleCheckService: (checked: boolean, service: GetAllServiceResponseItem) => void;
    readonly showPrice?: boolean;
    readonly showSelectEmployee?: boolean;
    readonly employeeValue?: string;
}

export default function MenuServiceItem(props: MenuServiceItemProps) {
    const { formatCurrency } = useLocalesFormat();
    const { t } = useTranslation();
    const {
        service,
        checkedService,
        onChangeEmployee,
        handleCheckService,
        showPrice,
        showSelectEmployee,
    } = props;

    const employees = useMemo(
        () =>
            service.employeeServices
                ?.map((employeeService) => employeeService.employee)
                .filter((o) => o.isAllowBooking 
                    && o.jobStatus === JOB_STATUS.ACTIVE),
        [service.employeeServices]
    );

    return (
        <Card className='service-item'>
            <FlexBox alignItems='start'>
                <FlexBox direction='column' gap={6}>
                    <Block>
                        <Text
                            color='#101828'
                            display='block'
                            fontSize={18}
                            fontWeight={600}
                        >
                            {service.name}
                        </Text>
                        <Text color='#667085'>{service.duration} {t(translateCodes.MINS)}</Text>
                    </Block>
                    {showPrice && (
                        <Text color='#101828'>
                            {formatCurrency(
                                service.serviceBranches?.[0]?.price
                            )}
                        </Text>
                    )}
                </FlexBox>
                <Checkbox
                    key={`${service.id}${service.name}`}
                    checked={checkedService}
                    onChange={(e) =>
                        handleCheckService(e.target.checked, service)
                    }
                />
            </FlexBox>
            {showSelectEmployee && checkedService && (
                <Spacer top={20}>
                    <AppointmentEmployeeField
                        handleChangedEmployee={onChangeEmployee}
                        name='employeeId'
                        value={props.employeeValue}
                        label={t(translateCodes.SELECT_EMPLOYEE)}
                        employees={employees}
                    />
                    <Spacer top={8}>
                        <Text>
                            {t(translateCodes.AUTO_ASSIGN_EMPLOYEE)}
                        </Text>
                    </Spacer>
                </Spacer>
            )}
        </Card>
    );
}
