import { HttpRequestData, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { EmployeeGetDetailResponse } from '@esg/business-resource-management';
import { env } from '../config';

interface GetAllEmployeesRequest extends HttpRequestData {
	readonly query: PaginationRequest & {
        isIncludeRelationship: boolean;
    };
}

export const getAllEmployeesLinkBooking = httpUtil.createHttpRequestMeta<
	GetAllEmployeesRequest,
	EmployeeGetDetailResponse[]
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/:slug/employees',
    method: 'GET',
});
