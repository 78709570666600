import { AppAvatar, FlexBox, SelectField, Text } from '@esg/ui';
import React from 'react';
import { BaseOptionType } from 'antd/es/select';
import { Checkbox } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { SelectProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { Employee } from '@esg/business-link-booking';
import { translateCodes } from '@/locales';

interface AppointmentEmployeeFieldProps extends SelectProps  {
	name: string;
    label: string;
	employees?: Employee[]
    optionRender?: (option: BaseOptionType) => React.ReactNode;
    handleChangedEmployee?: (value: string) => void;
}

export const AppointmentEmployeeField = (props: AppointmentEmployeeFieldProps) => {
    const { name, employees = [], label, handleChangedEmployee} = props;
    const { t } = useTranslation();

    const employeeOptions = employees.map((employee) => ({
        label: employee.fullName,
        value: employee.id,
        avatar: employee.avatar,
    }));
    
    const employeeOptionsRender = (option: BaseOptionType) => {
        return <FlexBox gap={12} alignItems='center'>
            <Checkbox checked={props.value?.includes(option.value)} /> 
            <FlexBox gap={12} alignItems='center'>
                <AppAvatar data={option} size='sm' icon={<UserOutlined />} />
                <Text>{option.label}</Text>
            </FlexBox>
        </FlexBox>;
    };
    return (
        <SelectField
            name={name}
            options={employeeOptions}
            label={label}
            listHeight={376}
            value={props.value}
            showSearch={true}
            optionRender={({ data }) => employeeOptionsRender(data)}
            onChange={handleChangedEmployee}
            maxTagCount='responsive'
            defaultValue={t(translateCodes.WITH_ANY_EMPLOYEE)}
        />
    );
};
