/* eslint-disable import/no-named-as-default */
import { AppButton, Block, FlexBox, Spacer, Text, useLocalesFormat } from '@esg/ui';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Card } from 'antd';
import './DoubleCalender.scss';
import React, { useEffect } from 'react';
import { dayjs } from '@esg/shared';
import { bookingUtil } from '@/utils';

interface DoubleCalenderProps {
    className?: string;
    onChange?: (date: Date) => void;
    value?: Date;
    numberTimeBlockActive?: number;
    isShowDoubleView?: boolean;
    disableDates?: Date[];
    maxDate?: Date;
    minDate?: Date;
    timezones?: string;
}

export default function DoubleCalender(props: DoubleCalenderProps) {
    const {onChange, value,isShowDoubleView, numberTimeBlockActive = 0, disableDates = []} = props;
    const { formatDate } = useLocalesFormat();

    const calendarRef = React.useRef(null);

    useEffect(() => {
        const dateNowTimezone = bookingUtil.ConvertDateToDateTimezone(dayjs().toDate(), props.timezones);
        const dataSetAbbr = dateNowTimezone.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        
        const elementAbbrNow = document.querySelector(`abbr[aria-label="${dataSetAbbr}"]`) as HTMLElement;

        if(elementAbbrNow){
            elementAbbrNow.parentElement?.classList.add('react-calendar__tile--now-timezone');
        }

    }, [formatDate, props.timezones, value]);

    const maxDate = props.maxDate ? bookingUtil.ConvertDateToDateTimezone(props.maxDate, props.timezones) : undefined;
    const minDate = props.minDate ? bookingUtil.ConvertDateToDateTimezone(props.minDate, props.timezones) : undefined;

    return (
        <Card>
            <Calendar 
                ref={calendarRef}
                view='month'
                locale='en-US'
                showDoubleView={isShowDoubleView} 
                showFixedNumberOfWeeks
                tileDisabled={({date}) => {
                    return disableDates.some((disabledDate) => dayjs(date).isSame(dayjs(disabledDate), 'day'));
                }}
                value={bookingUtil.ConvertDateToDateTimezone(value, props.timezones)}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(date) => onChange?.(date as Date)}
            />
            <Spacer top={16} />
            <FlexBox justifyContent='space-between'>
                <FlexBox gap={8} alignItems='center'>
                    <Block border='1px solid #E0E0E0' borderRadius={8}>
                        <Spacer top_bottom={8} left_right={12}>
                            {formatDate(dayjs(value))}
                        </Spacer>
                    </Block>
                    <Text color='#079455'>{numberTimeBlockActive} slot duration available</Text>
                </FlexBox>
                <AppButton 
                    onClick={() => {
                        onChange?.(new Date());
                    }}
                >
                    Today
                </AppButton>
            </FlexBox>
        </Card>
    );
}
