import { Image } from 'antd';
import { Block, Spacer } from '@esg/ui';
import { useMemo } from 'react';
import { GetCurrentPageBookingSettingResponse } from '@esg/business-link-booking';
import Title from '@/components/Text/Title';
import { translateCodes } from '@/locales';

interface HeaderPageProps {
    pageBookingSetting?: GetCurrentPageBookingSettingResponse
}

export default function HeaderPage(props: HeaderPageProps) {
    const {pageBookingSetting} = props;
    const defaultImageTopBanner = '/assets/img_holder.png';

    const imageTopBanner = useMemo(() => {
        if(pageBookingSetting?.showTopBanner && pageBookingSetting.topBannerUrl) {
            return pageBookingSetting.topBannerUrl;
        }

        return defaultImageTopBanner;

    },[pageBookingSetting?.showTopBanner, pageBookingSetting?.topBannerUrl]);

    return (
        <Block position='relative' className='header-page'>
            <Block className='header-page-image'>
                <Image height='100%' preview={false} src={imageTopBanner} width='100%'/>
            </Block>
            <Block 
                width='100%'
                position='absolute' 
                top='50%' 
                left='50%' 
                transform='translate(-50%, -50%)' 
                textAlign='center' 
                zIndex={1}
            >
                <Title color='white' level='1' translateCode={translateCodes.BOOK_AN_APPOINTMENT} />
                <Spacer height={8}/>
                <Title color='white' level='4' translateCode={translateCodes.BOOKING_HEADER_DESCRIPTION} />
            </Block>
        </Block>
    );
}
