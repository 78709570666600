import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../config';
import { FileUpload } from './../../upload/api/file/createFile';

interface GetCurrentPageBookingSettingRequest extends HttpRequestData {
}

export interface GetCurrentPageBookingSettingResponse {
    readonly id: string;
    readonly bookingSlug: string;
    readonly allowBookingOnline: boolean;
    readonly bookTabName: string;
    readonly bookTabInformation: string;
    readonly showTopBanner: boolean;
    readonly topBannerUrl: string;
    readonly showGallery: boolean;
    readonly galleryTabName: string;
    readonly galleryImages: FileUpload[];
}

export const getCurrentPageBookingSetting = httpUtil.createHttpRequestMeta<
    GetCurrentPageBookingSettingRequest,
    GetCurrentPageBookingSettingResponse
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/:slug/settings/page-booking',
    method: 'GET',
});
