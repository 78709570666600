import { AppButton, FlexBox, Spacer, Text, globalModalState, useLocalesFormat } from '@esg/ui';
import React from 'react';
import { useResetRecoilState } from 'recoil';
import { Divider } from 'antd';
import { AppointmentCreateResponse, GetAllBranchResponseItem, GetCurrentPageBookingSettingResponse } from '@esg/business-link-booking';
import { bookingUtil } from '@/utils';
import { translateCodes } from '@/locales';

interface ModalBookingSuccessProps {
    appointment: AppointmentCreateResponse;
    branch?: GetAllBranchResponseItem;
    pageBookingSetting?: GetCurrentPageBookingSettingResponse;
}

export default function ModalBookingSuccess(props: ModalBookingSuccessProps) {
    const {appointment, branch, pageBookingSetting} = props;

    const resetModalState = useResetRecoilState(globalModalState);
    const { formatDate } = useLocalesFormat();

    const {
        startTimeBooking, 
        endTimeBooking
    } = bookingUtil.getStartEndTimeBooking(appointment.appointmentServices, appointment.appointmentDate);

    return (
        <FlexBox direction='column'>
            <Text fontSize={18} fontWeight={700}>{pageBookingSetting?.bookTabName}</Text>
            <Text color='#475467'>{pageBookingSetting?.bookTabInformation}</Text>
            <Spacer top={20}/>
            <FlexBox gap={4}>
                <FlexBox width='50%' direction='column'>
                    <Text fontSize={16} color='#98A2B3'>{branch?.name}</Text>
                    <Text fontSize={16} color='#98A2B3'>{branch?.phoneNumber}</Text>
                </FlexBox>
                <Divider type='vertical' style={{height: '50px'}}/>
                <FlexBox width='50%' direction='column'>
                    <Text fontSize={16} color='#98A2B3'>{formatDate(appointment.appointmentDate)}</Text>
                    <Text fontSize={16} color='#98A2B3'>{`${formatDate(startTimeBooking, 'H:mm')} : ${formatDate(endTimeBooking, 'H:mm')}`}</Text>
                </FlexBox>
            </FlexBox>
            <Spacer top={20}/>
            <AppButton
                onClick={() => {
                    resetModalState();
                    window.location.reload();
                }}
                translateCode={translateCodes.BOOK_ANOTHER_APPOINTMENT}
            />
        </FlexBox>
    );
}
