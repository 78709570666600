import { Block, FieldArray, InputNumberFieldCustom, SwitchField } from '@esg/ui';
import { AppointmentServiceCreate, GetAllBookingOnlineSettingResponse, GetAllServiceCategoryResponseItem } from '@esg/business-link-booking';
import { useFormContext } from 'react-hook-form';
import { Tabs } from 'antd';
import React, { useMemo } from 'react';
import AppointmentServiceDetailField from './AppointmentServiceDetailField';
import Title from '@/components/Text/Title';

interface AppointmentServiceFieldProps {
    serviceCategories?: GetAllServiceCategoryResponseItem[];
    bookingOnlineSetting?: GetAllBookingOnlineSettingResponse;
}

export default function AppointmentServiceField(props: AppointmentServiceFieldProps) {
    const {serviceCategories, bookingOnlineSetting: setting} = props;
    const {watch, setValue} = useFormContext();
    const [guestActive, setGuestActive] = React.useState<number>(0);

    const numberOfGuest = watch('numberOfGuest');

    const numberOfGuestTabs = useMemo(() => {
        const tabs = Array.from({length: numberOfGuest}, (_, index) => index);

        return tabs.map((tab) => {
            return {
                key: tab.toString(),
                label: `Guest ${tab + 1}`,
                tab: `Guest ${tab + 1}`,
                children: <AppointmentServiceDetailField 
                    serviceCategories={serviceCategories} 
                    name={`appointmentServices.${tab}`} 
                    bookingOnlineSetting={setting}
                    index={tab}
                />,
            };
        });
    }, [numberOfGuest, serviceCategories, setting]);

    const disableNumberGuest = useMemo(()=>{
        const isOverLimitSlot = Number(numberOfGuest?? 0) >= Number(setting?.bookingSlotLimit ?? 0)  && setting?.allowBookingSlotLimit;

        return isOverLimitSlot;

    },[numberOfGuest, setting?.allowBookingSlotLimit, setting?.bookingSlotLimit]);

    return (
        <FieldArray name='appointmentServices' isUseWrapper={false}>
            {(fieldArrayProps) => {
                return <>
                    {setting?.allowGroupAppointment && (
                        <Block className='number-of-guest'>
                            <Title level='3' translateCode='Number of Guest'/>
                            <InputNumberFieldCustom 
                                name='numberOfGuest'
                                disabled={disableNumberGuest}
                                max={setting?.bookingSlotLimit}
                                onChange={(value) => {
                                    if(!value) return;
                                    const appointmentServices = watch('appointmentServices');
                                    const newAppointmentServices = Array.from({length: value as number}, (_, index) => {
                                        return appointmentServices?.[index] || {
                                            appointmentServiceDetails: [],
                                        };
                                    });
                                    fieldArrayProps.replace(newAppointmentServices);
                                }}
                            />
                        </Block>
                    )}
                    <Block className='wrapper-field'>
                        <Title translateCode='Select services'/>
                        {Number(numberOfGuest) === 1 ? (
                            <AppointmentServiceDetailField
                                bookingOnlineSetting={setting} 
                                serviceCategories={serviceCategories} 
                                name='appointmentServices.0' 
                                index={0}
                            />
                        ) : 
                            <Block position='relative'>
                                <SwitchField
                                    className={`apply-all-guests-switch ${guestActive === 0 ? 'show' : 'hide'}`}
                                    name='applyAllGuests'
                                    description='Apply the same service for all guests'
                                    directionLabel='row-reverse'
                                    onChange={(value) => {
                                        if(value) {
                                            const appointmentServiceFirst = watch('appointmentServices.0') as AppointmentServiceCreate;
                                            const appointmentServices = watch('appointmentServices') as AppointmentServiceCreate[];
                                            setValue('appointmentServices', appointmentServices.map((_) => appointmentServiceFirst));
                                            setValue('appointmentDate', undefined);
                                        }
                                    }}
                                />
                                <Tabs onChange={(value)=> setGuestActive(Number(value))} items={numberOfGuestTabs} />
                            </Block>
                        }
                    </Block>
                </>;
            }}
        </FieldArray>
    );
}
