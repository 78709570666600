import { Block, FlexBox, Text } from '@esg/ui';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Checkbox, Collapse,Form } from 'antd';
import { CollapseProps } from 'antd/lib';
import { AppointmentServiceCreate, GetAllBookingOnlineSettingResponse, GetAllServiceCategoryResponseItem, GetAllServiceResponseItem } from '@esg/business-link-booking';
import { dayjs } from '@esg/shared';
import { useCallback, useMemo } from 'react';
import MenuServiceItem from '../menu-order/MenuServiceItem';
import { bookingUtil } from '@/utils/bookingUtils';

interface AppointmentServiceDetailFieldProps {
    readonly serviceCategories?: GetAllServiceCategoryResponseItem[]
    readonly name: string;
    readonly bookingOnlineSetting?: GetAllBookingOnlineSettingResponse;
    readonly index?: number;
}

export default function AppointmentServiceDetailField(props: AppointmentServiceDetailFieldProps) {
    const { serviceCategories, name, bookingOnlineSetting, index } = props;
    const { watch, control, setValue } = useFormContext();
    
    const appointmentServices = watch('appointmentServices') as AppointmentServiceCreate[];
    const applyAllGuests = watch('applyAllGuests');
    const appointmentDate = watch('appointmentDate');

    const appointmentService = appointmentServices?.[index ?? 0];
    const appointmentServicesDetails = appointmentService?.appointmentServiceDetails;

    const showPrice = bookingOnlineSetting?.showServicePrice;
    const showSelectEmployee = bookingOnlineSetting?.requiredSelectEmployee;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fieldArrayReturn = useFieldArray<any, any, any>({
        control,
        name: `${name}.appointmentServiceDetails`,
    });

    const handleCheckService = useCallback((checked: boolean, service: GetAllServiceResponseItem) => {
        if(checked) {
            const startTime = bookingUtil.getStartTimeBookingServiceDetail(
                appointmentServicesDetails, 
                dayjs(appointmentDate), 
                fieldArrayReturn.fields.length
            );
            
            fieldArrayReturn.insert(fieldArrayReturn.fields.length, {
                serviceId: service.id,
                service: service,
                startTime: startTime,
                endTime: dayjs(startTime).add(service.duration, 'minute').toISOString(),
                appointmentServiceEmployees: [],
            });
        } else {
            const indexOfService = fieldArrayReturn.fields.findIndex((field) => field.serviceId === service.id);
            fieldArrayReturn.remove(indexOfService);
        }

        if(applyAllGuests && index === 0) {
            const appointmentService = watch(name) as AppointmentServiceCreate;
            setValue('appointmentServices', appointmentServices.map((_) => appointmentService));
            setValue('appointmentDate', undefined);
        }
    }, [applyAllGuests, index, appointmentServicesDetails, appointmentDate, fieldArrayReturn, watch, name, setValue, appointmentServices]);

    const onChangeEmployee = useCallback((employeeId: string, indexOfService: number) => {
        const valueUpdate = fieldArrayReturn.fields[indexOfService];
        fieldArrayReturn.update(indexOfService, { 
            ...valueUpdate,
            appointmentServiceEmployees: [{ employeeId: employeeId }]
        });

        if(applyAllGuests && index ===0) {
            const appointmentService = watch(name) as AppointmentServiceCreate;
            setValue('appointmentServices', appointmentServices.map((_) => appointmentService));
        }
    }, [applyAllGuests, index, fieldArrayReturn, watch, name, setValue, appointmentServices]);

    const menuOptions : CollapseProps['items'] = useMemo(()=> serviceCategories?.map((category) => {
        return {
            key: `${category?.id}${index}`,
            label: <><Text textTransform='capitalize' fontSize={16} fontWeight={700}>{`${category?.name}`}</Text></>,
            children: 
            <FlexBox direction='column' gap={16}>
                <Block display='none'>
                    <Checkbox/>
                </Block>
                <FlexBox gap={16} direction='column'>
                    {category.services?.map((service, index) => {
                        const indexOfService = appointmentServicesDetails.findIndex((field) => field.serviceId === service.id);
                        const checkedService = indexOfService !== -1;
                        const employeeValue = appointmentServicesDetails[indexOfService]?.appointmentServiceEmployees?.[0]?.employeeId;
                        return (
                            <MenuServiceItem
                                key={`${service.id}${index}`}
                                service={service}
                                checkedService={checkedService}
                                onChangeEmployee={(employee) => onChangeEmployee(employee, indexOfService)}
                                handleCheckService={handleCheckService}
                                showPrice={showPrice}
                                employeeValue={employeeValue}
                                showSelectEmployee={showSelectEmployee}
                            />
                        );  
                    })}
                </FlexBox>
            </FlexBox>
        };
    }), [serviceCategories, appointmentServicesDetails, handleCheckService, onChangeEmployee, showPrice, showSelectEmployee, index]);

    return (
        <Form.Item>
            <Collapse
                destroyInactivePanel
                bordered={false}
                expandIconPosition='end'
                items={menuOptions}
            />
        </Form.Item>
    );

}
