import { HttpRequestData, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../config';

interface GetAllTimeOffRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllTimeOffResponseItem {
    readonly employeeId: string;
    readonly disableFrom: string;
    readonly disableTo: string;
}

export const getAllTimeOff = httpUtil.createHttpRequestMeta<
    GetAllTimeOffRequest,
    GetAllTimeOffResponseItem[]
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/:slug/time-offs',
    method: 'GET',
});
