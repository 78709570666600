import {
    Block,
    FlexBox,
    Spacer,
    Text,
    TextAreaField,
    useHttpQuery,
} from '@esg/ui';
import {
    GetAllBookingOnlineSettingResponse,
    GetAllBranchResponseItem,
    GetAllTimeOffResponseItem,
    GetCurrentPageBookingSettingResponse,
    getAllServiceCategories,
    getAllServicesLinkBooking,
} from '@esg/business-link-booking';
import { useMemo } from 'react';
import { Image } from 'antd';
import { dayjs } from '@esg/shared';
import AppointmentServiceField from './fields/AppointmentServiceField';
import SelectBranchField from './fields/SelectBranchField';
import CustomerInfoField from './fields/CustomerInfoField';
import AppointmentDateField from './fields/AppointmentDateField';
import Title from '@/components/Text/Title';
import { bookingUtil } from '@/utils/bookingUtils';
import { translateCodes } from '@/locales';

interface BookingFormProps {
    readonly branches?: GetAllBranchResponseItem[];
    readonly currentBranch?: GetAllBranchResponseItem;
    readonly bookingSetting?: GetAllBookingOnlineSettingResponse;
    readonly slug?: string;
    readonly timeOffs?: GetAllTimeOffResponseItem[];
    readonly pageBookingSetting?: GetCurrentPageBookingSettingResponse
}

export default function BookingForm(props: BookingFormProps) {
    const { branches, bookingSetting, slug, timeOffs, currentBranch, pageBookingSetting } = props;
    const { data: serviceCategories } = useHttpQuery(getAllServiceCategories, {
        pathData: { slug },
    });

    const { data: services } = useHttpQuery(getAllServicesLinkBooking, {
        pathData: { slug },
    });

    const disableDates = useMemo(
        () =>
            bookingSetting?.bookingOnlineDateOffs?.map((o) =>
                bookingUtil.ConvertDateToDateTimezone(
                    o.dateOff,
                    currentBranch?.timezone
                )
            ) ?? [],
        [bookingSetting, currentBranch]
    );

    const serviceCategoriesMap = useMemo(
        () =>
            serviceCategories?.map((serviceCategory) => ({
                ...serviceCategory,
                services: services?.filter(
                    (service) => service.serviceCategoryId === serviceCategory.id) ?? [],
            })).filter((o) => o.services?.length > 0) ?? [],
        [serviceCategories, services]
    );

    const maxDateBook = useMemo(
        () =>
            bookingSetting?.bookingFeatureLimit
                ? dayjs().add(bookingSetting?.bookingFeatureLimit, 'day').toDate()
                : undefined,
        [bookingSetting]
    );

    const showGallery = pageBookingSetting?.showGallery && pageBookingSetting?.galleryImages?.length > 0;

    return (
        <Block className='booking-form'>
            <SelectBranchField branches={branches} />
            <AppointmentServiceField
                serviceCategories={serviceCategoriesMap}
                bookingOnlineSetting={bookingSetting}
            />
            <AppointmentDateField
                currentBranch={currentBranch}
                slotDurationMinute={bookingSetting?.timeSlotDuration}
                timeOffs={timeOffs}
                disableDates={disableDates}
                maxDate={maxDateBook}
                minDate={dayjs().toDate()}
            />
            <CustomerInfoField />
            <FlexBox direction='column' className='wrapper-field'>
                <Title level='3' translateCode='Booking notes' />
                <Text color='#667085' translateCode={translateCodes.BOOKING_NOTES_DESCRIPTION} />
                <TextAreaField
                    label=''
                    name='notes'
                    placeholder={translateCodes.BOOKING_NOTES_PLACEHOLDER}
                />
            </FlexBox>
            {showGallery && (
                <Spacer bottom={24} className='wrapper-field'>
                    <Title translateCode={pageBookingSetting.galleryTabName} />
                    <FlexBox gap={4} className='gallery-image'>
                        {pageBookingSetting?.galleryImages?.map((image) => (
                            <Block
                                key={image.name}
                                className='gallery-image-item'
                            >
                                <Image
                                    src={image.uri}
                                    width={230}
                                    height={309}
                                />
                            </Block>
                        ))}
                    </FlexBox>
                </Spacer>
            )}
        </Block>
    );
}
