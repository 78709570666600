export const translateCodes = {
    AUTO_ASSIGN_EMPLOYEE: 'AUTO_ASSIGN_EMPLOYEE',
    WITH_ANY_EMPLOYEE: 'WITH_ANY_EMPLOYEE',
    MINS: 'MINS',
    FIRST_NAME: 'FIRST_NAME',
    LAST_NAME: 'LAST_NAME',
    YOUR_EMAIL: 'YOUR_EMAIL',
    PHONE: 'PHONE',
    FIRST_NAME_REQUIRED: 'FIRST_NAME_REQUIRED',
    EMAIL_REQUIRED: 'EMAIL_REQUIRED',
    SELECT_BRANCH: 'SELECT_BRANCH',
    BOOKING_NOTES_DESCRIPTION: 'BOOKING_NOTES_DESCRIPTION',
    BOOKING_NOTES_PLACEHOLDER: 'BOOKING_NOTES_PLACEHOLDER',
    BOOK_AN_APPOINTMENT: 'BOOK_AN_APPOINTMENT',
    BOOKING_HEADER_DESCRIPTION: 'BOOKING_HEADER_DESCRIPTION',
    BOOK_ANOTHER_APPOINTMENT: 'BOOK_ANOTHER_APPOINTMENT',
    HOTLINE: 'HOTLINE',
    OPENING_HOURS: 'OPENING_HOURS',
    GUEST: 'GUEST',
    MINUTES_AND_WITH: 'MINUTES_AND_WITH',
    ANY_EMPLOYEE: 'ANY_EMPLOYEE',
    SELECT_EMPLOYEE: 'SELECT_EMPLOYEE',
    TOTAL: 'TOTAL',
    CONTINUE: 'CONTINUE',
};

export type TranslateResources = Record<keyof typeof translateCodes, string>;