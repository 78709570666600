import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './home/Home';

export const Router = () => {

    const domain =  window.location.hostname.split('.')[0]; 

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/:slug' element={<Home />} /> {/** booking.izisalon.vn/hainv */}
                <Route path='*' element={<Home domain={domain} />} /> {/** hainv.izisalon.vn/ */}
            </Routes>
        </BrowserRouter>
    );
};