import { Block, Text } from '@esg/ui';
import React from 'react';
import {Divider} from 'antd';

import './Copyright.scss';

export default function Copyright() {
    return (
        <Block className='copyright'>
            <Divider className='divider' />
            <Text color='#344054'>Copyright 2024 by Izisalon.com. All right reserved</Text>
        </Block>
    );
}
