import {
    AppointmentCreateRequest,
    AppointmentServiceCreate,
    getAllTimeOff,
} from '@esg/business-link-booking';
import { convertSameDate, dayjs } from '@esg/shared';
import { useHttpQuery } from '@esg/ui';
import { useMemo } from 'react';

import { bookingUtil } from '@/utils';

export default function useTimeOffsBooking(appointment: AppointmentCreateRequest['body'], slug?: string) {
    const appointmentServices = appointment.appointmentServices as AppointmentServiceCreate[];
    const employeeIds = bookingUtil.getEmployeeIdsEachNumberOfGuest(appointmentServices);
   
    const { data: timeOffs } = useHttpQuery(getAllTimeOff, {
        pathData: { slug },
        query: {
            employeeIds: employeeIds as string[],
            startDate: dayjs(appointment.appointmentDate).startOf('day').toISOString(),
            endDate: dayjs(appointment.appointmentDate).endOf('day').toISOString(),
            numberOfGuest: appointment.numberOfGuest,
            branchId: appointment.branchId,
        },
    });

    const result = useMemo(() => {
        const appointmentDate = dayjs(appointment.appointmentDate);
        if (!timeOffs) {
            return [];
        }

        return timeOffs.map((to) => ({
            ...to,
            disableFrom: convertSameDate(appointmentDate, to.disableFrom).toISOString(),
            disableTo: convertSameDate(appointmentDate, to.disableTo).toISOString(),
        }));
    }, [appointment.appointmentDate, timeOffs]);

    return result;
}
