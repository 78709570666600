import React, { useMemo } from 'react';
import {
    AppointmentCreateRequest,
    GetAllBookingOnlineSettingResponse,
    GetAllBranchResponseItem,
    GetCurrentPageBookingSettingResponse,
} from '@esg/business-link-booking';
import { useFormContext } from 'react-hook-form';
import useTimeOffsBooking from '../hook/useTimeOffsBooking';
import BookingForm from './BookingForm';
import OrderPayment from './OrderPayment';

interface BookingContentProps {
    slugToQuery?: string;
    branches?: GetAllBranchResponseItem[];
    bookingOnlineSettings?: GetAllBookingOnlineSettingResponse[];
    pageBookingSetting?: GetCurrentPageBookingSettingResponse
}

export default function BookingContent(props: BookingContentProps) {
    const { slugToQuery, branches, bookingOnlineSettings, pageBookingSetting } = props;
    const { watch } = useFormContext();

    const bookingValues = watch() as AppointmentCreateRequest['body'];

    const bookingSetting = useMemo(()=> bookingOnlineSettings?.find(
        (o) => o.branchId === bookingValues.branchId
    ),[bookingOnlineSettings, bookingValues.branchId]);

    const currentBranch = useMemo(
        () => branches?.find((branch) => branch.id === bookingValues.branchId),
        [branches, bookingValues.branchId]
    );

    const timeOffs = useTimeOffsBooking(bookingValues, slugToQuery);

    return (
        <>
            <BookingForm
                slug={slugToQuery}
                branches={branches}
                bookingSetting={bookingSetting}
                timeOffs={timeOffs}
                currentBranch={currentBranch}
                pageBookingSetting={pageBookingSetting}
            />
            <OrderPayment
                slug={slugToQuery}
                currentBranch={currentBranch}
                bookingSetting={bookingSetting}
                timeOffs={timeOffs}
            />
        </>
    );
}
