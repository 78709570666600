import {
    AppButton,
    Block,
    FlexBox,
    IconCalendar,
    IconClock,
    IconOrder,
    Spacer,
    Text,
    useHttpQuery,
    useLocalesFormat,
} from '@esg/ui';
import { useFormContext } from 'react-hook-form';
import { Card, Image, Divider, Badge } from 'antd';
import {
    AppointmentCreateRequest,
    GetAllBookingOnlineSettingResponse,
    GetAllBranchResponseItem,
    GetAllTimeOffResponseItem,
    getAllEmployeesLinkBooking,
} from '@esg/business-link-booking';
import React, { useMemo, useState } from 'react';
import { dayjs } from '@esg/shared';
import { useTranslation } from 'react-i18next';
import { bookingUtil } from '@/utils/bookingUtils';
import { translateCodes } from '@/locales';

interface OrderPaymentProps {
    readonly currentBranch?: GetAllBranchResponseItem;
    readonly bookingSetting?: GetAllBookingOnlineSettingResponse;
    readonly slug?: string;
    readonly timeOffs: GetAllTimeOffResponseItem[];
}

export default function OrderPayment(props: OrderPaymentProps) {
    const { bookingSetting, slug, timeOffs, currentBranch } = props;
    const { watch } = useFormContext();
    const { formatCurrency, formatDate } = useLocalesFormat();
    const {t} = useTranslation();

    const [showOrderPayment, setShowOrderPayment] = useState(false);
    const bookingValues = watch() as AppointmentCreateRequest['body'];
    const appointmentServices = bookingValues.appointmentServices;

    const { startTimeBooking, endTimeBooking } = bookingUtil.getStartEndTimeBooking(
        bookingValues.appointmentServices,
        bookingValues.appointmentDate
    );

    const { data: employeesResponse } = useHttpQuery(getAllEmployeesLinkBooking, {
        pathData: { slug },
    });

    const isDisableSubmit = useMemo(
        () =>
            bookingUtil.isDisableSubmit(
                appointmentServices,
                timeOffs,
                bookingValues.appointmentDate
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [bookingValues.appointmentDate, timeOffs, JSON.stringify(bookingValues.appointmentServices)]
    );

    const totalPrice = useMemo(
        () => bookingUtil.calcTotalPrice(bookingValues.appointmentServices),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(bookingValues.appointmentServices)]
    );

    const numberService = useMemo(
        () => bookingUtil.calcNumberService(bookingValues.appointmentServices),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(bookingValues.appointmentServices)]
    );

    const { openTime, closeTime } = useMemo(
        () =>
            bookingUtil.getOpenCloseTimeBranch(currentBranch!, dayjs(bookingValues.appointmentDate)),
        [currentBranch, bookingValues.appointmentDate]
    );

    return (
        <Block className='order-payment'>
            <Block position='sticky' top={0}>
                <Card>
                    <Block
                        className={`order-payment-content ${
                            showOrderPayment ? 'show' : 'hide'
                        }`}
                    >
                        <FlexBox gap={24}>
                            <Image
                                src={currentBranch?.logo ?? '/assets/Logo.png'}
                                width={72}
                                height={72}
                            />
                            <FlexBox
                                direction='column'
                                justifyContent='space-between'
                            >
                                <Text fontSize={16} fontWeight={700}>
                                    {currentBranch?.name}
                                </Text>
                                <Text color='#667085'>
                                    {t(translateCodes.HOTLINE)}: {currentBranch?.phoneNumber}
                                </Text>
                                <Text color='#667085'>
                                    {t(translateCodes.OPENING_HOURS)}: &nbsp;
                                    <Text color='#101828'>
                                        {dayjs(openTime).format('HH:mm')} -{' '}
                                        {dayjs(closeTime).format('HH:mm')}
                                    </Text>
                                </Text>
                            </FlexBox>
                        </FlexBox>
                        <Divider />
                        <Spacer bottom={20}>
                            <FlexBox gap={8} alignItems='center'>
                                <IconCalendar
                                    width={19}
                                    height={19}
                                    color='#98A2B3'
                                />
                                <Text fontSize={16} color='#98A2B3'>
                                    {bookingValues.appointmentDate
                                        ? formatDate(bookingValues.appointmentDate)
                                        : '-'}
                                </Text>
                            </FlexBox>
                            <FlexBox gap={8} alignItems='center'>
                                <IconClock color='#98A2B3' />
                                <Text fontSize={16} color='#98A2B3'>
                                    {bookingValues.appointmentDate
                                        ? `${formatDate(startTimeBooking, 'HH:mm')} : ${formatDate(endTimeBooking,'HH:mm')}`
                                        : '-'}
                                </Text>
                            </FlexBox>
                        </Spacer>
                        <FlexBox
                            direction='column'
                            gap={16}
                            className='order-payment-services'
                        >
                            {appointmentServices?.map((apmService, index) => (
                                <FlexBox
                                    gap={16}
                                    direction='column'
                                    key={index}
                                >
                                    {appointmentServices.length > 1 && (
                                        <Divider
                                            orientation='left'
                                            orientationMargin='0'
                                            dashed
                                            style={{ margin: 0 }}
                                        >
                                            <Text
                                                color='#667085'
                                                fontSize={16}
                                                fontWeight={700}
                                            >
                                                {t(translateCodes.GUEST)} {index + 1}
                                            </Text>
                                        </Divider>
                                    )}
                                    {apmService?.appointmentServiceDetails?.map(
                                        (apmServiceDetail) => {
                                            const employeeName =
                                                employeesResponse?.find(
                                                    (employee) =>
                                                        employee.id === apmServiceDetail.appointmentServiceEmployees?.[0]?.employeeId
                                                )?.fullName;
                                            return (
                                                <FlexBox
                                                    justifyContent='space-between'
                                                    key={apmServiceDetail.serviceId}
                                                >
                                                    <Block>
                                                        <Text
                                                            fontSize={16}
                                                            fontWeight={600}
                                                            display='block'
                                                            maxLines={1}
                                                            maxWidth={160}
                                                        >
                                                            {apmServiceDetail.service?.name}
                                                        </Text>
                                                        <Text color='#667085'>
                                                            {apmServiceDetail.service?.duration}
                                                            {t(translateCodes.MINUTES_AND_WITH)}
                                                        </Text>
                                                        <Text
                                                            fontSize={16}
                                                            fontWeight={600}
                                                            color='#475467'
                                                        >
                                                            {employeeName ?? t(translateCodes.ANY_EMPLOYEE)}
                                                        </Text>
                                                    </Block>
                                                    {bookingSetting?.showServicePrice && (
                                                        <Text
                                                            fontSize={16}
                                                            fontWeight={600}
                                                        >
                                                            {formatCurrency(
                                                                apmServiceDetail.service?.serviceBranches?.[0]?.price ?? 0
                                                            )}
                                                        </Text>
                                                    )}
                                                </FlexBox>
                                            );
                                        }
                                    )}
                                </FlexBox>
                            ))}
                        </FlexBox>
                        <Divider />
                    </Block>
                    <Block className='order-payment-footer'>
                        <Badge count={numberService} className='icon-order'>
                            <AppButton
                                icon={<IconOrder />}
                                onClick={() => setShowOrderPayment(!showOrderPayment)}
                            />
                        </Badge>
                        {bookingSetting?.showServicePrice && (
                            <FlexBox
                                justifyContent='space-between'
                                width='100%'
                            >
                                <Text
                                    className='price-label'
                                    color='#101828'
                                    fontSize={20}
                                    fontWeight={700}
                                    translateCode={translateCodes.TOTAL}
                                />
                                <Text fontSize={20} fontWeight={700}>
                                    {formatCurrency(totalPrice)}
                                </Text>
                            </FlexBox>
                        )}
                        <Spacer top={24} />
                        <AppButton
                            disabled={isDisableSubmit}
                            type='primary'
                            htmlType='submit'
                            className='btn-continue'
                            translateCode={translateCodes.CONTINUE}
                        />
                    </Block>
                </Card>
            </Block>
        </Block>
    );
}
