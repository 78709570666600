import { TranslateResources } from './translateCodes';

const translation: Partial<TranslateResources> = {
    AUTO_ASSIGN_EMPLOYEE: 'The system will automatically assign employee when you arrive at the salon',
    MINS: 'mins',
    WITH_ANY_EMPLOYEE: 'With any employee',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    YOUR_EMAIL: 'Your Email',
    PHONE: 'Phone number',
    FIRST_NAME_REQUIRED: 'First name is required',
    EMAIL_REQUIRED: 'Email is required',
    SELECT_BRANCH: 'Select branch',
    BOOKING_NOTES_DESCRIPTION: 'Include comments or requests about your booking',
    BOOKING_NOTES_PLACEHOLDER: 'Enter a description...',
    BOOK_AN_APPOINTMENT: 'Book an Appointment',
    BOOKING_HEADER_DESCRIPTION: 'Please fill out the appointment form below to make an appointment',
    BOOK_ANOTHER_APPOINTMENT: 'Book another appointment',
    HOTLINE: 'Hotline',
    OPENING_HOURS: 'Opening hours',
    GUEST: 'Guest',
    MINUTES_AND_WITH: 'mins | with',
    ANY_EMPLOYEE: 'Any employee',
    SELECT_EMPLOYEE: 'Select employee',
    TOTAL: 'Total',
    CONTINUE: 'Continue',
};

export const en = {
    translation,
};
