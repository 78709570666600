import { HttpRequestData, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';
import { env } from '../config';
import { GetAllServiceResponseItem } from './getAllServicesLinkBooking';

interface GetAllServiceCategoryRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

export interface GetAllServiceCategoryResponseItem {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly services?: GetAllServiceResponseItem[]
}

export const getAllServiceCategories = httpUtil.createHttpRequestMeta<
    GetAllServiceCategoryRequest,
    GetAllServiceCategoryResponseItem[]
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/:slug/service-categories',
    method: 'GET',
});
