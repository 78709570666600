import { HttpRequestData, httpUtil } from '@esg/framework';
import { JOB_STATUS, PaginationRequest } from '@esg/shared';

import { CreateFacilityResponse } from '@esg/business-resource-management';
import { env } from '../config';

interface GetAllServiceRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

interface ServiceCategory {
    id: string;
    name: string;
    description?: string;
}

export interface Employee {
    readonly id: string;
    readonly avatar: string;
    readonly fullName: string;
    readonly isAllowBooking: boolean;
    readonly jobStatus: JOB_STATUS;
}

interface EmployeeServices {
    readonly id?: string;
    readonly employeeId?: string;
    readonly serviceId?: string;
    readonly service?: GetAllServiceResponseItem;
    readonly employee: Employee;
}

interface ServiceBranch {
    readonly id: string;
    readonly branchId: string;
    readonly price: number;
    readonly tax: number;
    readonly serviceId?: string;
}

interface ServicesFacility {
    id?: string;
    facilityId?: string;
    serviceId?: string;
    service?: GetAllServiceResponseItem;
    facility?: CreateFacilityResponse;
}
interface ServiceCategory {
    id: string;
    name: string;
    description?: string;
}

export interface GetAllServiceResponseItem {
    readonly id: string;
    name: string;
    serviceCategoryId: string;
    duration: number;
    serviceBranches: ServiceBranch[];
    description: string;
    category?: ServiceCategory;
    isAllowOnlineBooking: boolean;
    serviceCategory?: ServiceCategory;
    employeeServices?: EmployeeServices[];
    serviceFacilities?: ServicesFacility[];
}

export const getAllServicesLinkBooking = httpUtil.createHttpRequestMeta<
    GetAllServiceRequest,
    GetAllServiceResponseItem[]
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/:slug/services',
    method: 'GET',
});
