import { Block, FlexBox, InputField, Spacer } from '@esg/ui';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Title from '@/components/Text/Title';
import { translateCodes } from '@/locales';

export default function CustomerInfoField() {
    const { formState } = useFormContext();
    const { errors, submitCount } = formState;
    const { t } = useTranslation();

    const firstErrorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (Object.keys(errors).length > 0 && firstErrorRef.current) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [errors, submitCount]);
    
    return (
        <Block className='wrapper-field customer-info'>
            <Title translateCode='Customer Information' />
            <FlexBox gap={16} className='customer-name'>
                <InputField 
                    name='customer.firstName' 
                    label={translateCodes.FIRST_NAME}
                    rules={{ 
                        required: t(translateCodes.FIRST_NAME_REQUIRED),
                    }}
                />
                <InputField 
                    name='customer.lastName' 
                    label={translateCodes.LAST_NAME} 
                />
            </FlexBox>
            <Spacer top={16}/>
            <FlexBox gap={16} className='customer-contact'>
                <InputField 
                    name='customer.email' 
                    label={translateCodes.YOUR_EMAIL}
                    rules={{ required: t(translateCodes.EMAIL_REQUIRED) }}
                />
                <InputField 
                    name='customer.phone' 
                    label={translateCodes.PHONE} 
                />
            </FlexBox>
            <div ref={firstErrorRef} />
        </Block>
    );
}
