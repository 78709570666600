import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../config';
import { IWorkingTime } from './../../organization/api/branch/getAllBranches';

interface GetAllBranchesRequest extends HttpRequestData {
}

export interface GetAllBranchResponseItem {
    readonly id: string;
    readonly name: string;
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly postalCode: string;
    readonly country: string;
    readonly logo: string;
    readonly phoneNumber: string;
    readonly timezone?: string;
    readonly languages?: string;
    readonly currency?: string;
    readonly businessHour: IWorkingTime;
    readonly dateTimeFormat?: string;
}

export const getAllBranches = httpUtil.createHttpRequestMeta<
    GetAllBranchesRequest,
    GetAllBranchResponseItem[]
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/:slug/branches',
    method: 'GET',
});
