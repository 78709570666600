import { AppButton, Block, Spacer, Text } from '@esg/ui';

export interface TimeBlock {
    startHour: number;
    endHour?: number;
    disable?: boolean;
}

//hour is decimal number, for example 7.25 is 7:15
const getTimeFromHour = (hour: number) => {
    const minuteNumber = Math.round((hour - Math.floor(hour)) * 60);
    if(minuteNumber === 60) return `${Math.floor(hour) + 1}:00`;

    const hourString = Math.floor(hour).toString();
    const minuteString = minuteNumber < 10 ? `0${minuteNumber}` : minuteNumber.toString();
    return `${hourString}:${minuteString}`;
};

interface TimeBlocksProps {
    selectTimeBlock: (timeBlock: TimeBlock) => void;
    timeBlockActive?: TimeBlock;
    timeBlocks?: TimeBlock[];
    isMobile?: boolean;
    isLoading?: boolean;
}

export default function TimeBlocks(props: TimeBlocksProps) {
    const { timeBlocks, isMobile} = props;

    return (
        <Block className='wrapper-field'>
            <Text fontSize={20} fontWeight={600}>Start time</Text>
            <Spacer top={16}/>
            <Block className='wrapper-time-blocks'>
                {timeBlocks?.map((timeBlock, index) => {
                    return (
                        <AppButton 
                            className='time-block'
                            disabled={timeBlock.disable}
                            type={props.timeBlockActive?.startHour.toFixed(2) === timeBlock.startHour.toFixed(2) ? 'primary' : 'default'}
                            key={index} 
                            onClick={() => props.selectTimeBlock(timeBlock)}
                            width={isMobile ? 100 : 120} 
                        >
                            {getTimeFromHour(timeBlock.startHour)}
                        </AppButton>
                    );
                })}
            </Block>
        </Block>
    );
}
