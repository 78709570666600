import React from 'react';
import { IconProps } from '../../Types';

export const IconOrder = (props: IconProps) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M4.16613 11.6665H15.1126C15.9566 11.6665 16.3786 11.6665 16.7146 11.5091C17.0105 11.3705 17.2592 11.148 17.4296 10.8691C17.6231 10.5526 17.6697 10.1332 17.7629 9.29432L18.2504 4.90679C18.2789 4.65057 18.2931 4.52246 18.2519 4.4233C18.2157 4.33622 18.1511 4.26398 18.0685 4.2184C17.9745 4.1665 17.8456 4.1665 17.5878 4.1665H3.74947M1.66602 1.6665H2.70638C2.9269 1.6665 3.03716 1.6665 3.12342 1.70844C3.19936 1.74536 3.2623 1.80448 3.30388 1.87797C3.35112 1.96145 3.35799 2.0715 3.37175 2.29159L4.12695 14.3748C4.1407 14.5948 4.14758 14.7049 4.19482 14.7884C4.2364 14.8619 4.29933 14.921 4.37528 14.9579C4.46154 14.9998 4.5718 14.9998 4.79232 14.9998H15.8327M6.24935 17.9165H6.25768M13.7493 17.9165H13.7577M6.66602 17.9165C6.66602 18.1466 6.47947 18.3332 6.24935 18.3332C6.01923 18.3332 5.83268 18.1466 5.83268 17.9165C5.83268 17.6864 6.01923 17.4998 6.24935 17.4998C6.47947 17.4998 6.66602 17.6864 6.66602 17.9165ZM14.166 17.9165C14.166 18.1466 13.9795 18.3332 13.7493 18.3332C13.5192 18.3332 13.3327 18.1466 13.3327 17.9165C13.3327 17.6864 13.5192 17.4998 13.7493 17.4998C13.9795 17.4998 14.166 17.6864 14.166 17.9165Z'
                stroke='#344054'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
