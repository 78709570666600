import { AppointmentServiceCreate, GetAllBranchResponseItem, GetAllTimeOffResponseItem } from '@esg/business-link-booking';
import { Dayjs, dayjs, defaultTimezone } from '@esg/shared';

export const bookingUtil = {
    getTimeFromHour: (hour: number) => {
        const hourString = Math.floor(hour).toString();
        const minuteNumber = Math.round((hour - Math.floor(hour)) * 60);
        const minuteString = minuteNumber < 10 ? `0${minuteNumber}` : minuteNumber.toString();
        return `${hourString}:${minuteString}`;
    },

    getDurationHourBooking: (appointmentServices: AppointmentServiceCreate[], appointmentDate?: string) => {
        const startHour = dayjs(appointmentDate).hour() + dayjs(appointmentDate).minute() / 60;

        const endHourMaxOfBooking = appointmentServices?.reduce((max, appointmentService) => {
            const timeEndMax = appointmentService.appointmentServiceDetails.reduce((max, appointmentServiceDetail) => {
                return Math.max(max, dayjs(appointmentServiceDetail.endTime).hour() + dayjs(appointmentServiceDetail.endTime).minute() / 60);
            }, 0);
            return Math.max(max, timeEndMax);
        }, startHour);

        return endHourMaxOfBooking - startHour;
    },

    getStartEndTimeBooking: (appointmentServices: AppointmentServiceCreate[], appointmentDate?: string) => {
        const startTimeBooking = dayjs(appointmentDate);

        const hourOfBooking = bookingUtil.getDurationHourBooking(appointmentServices, appointmentDate);

        const endTimeBooking = dayjs(appointmentDate).add(hourOfBooking, 'hour');

        return {startTimeBooking, endTimeBooking};
    },
    getOpenCloseTimeBranch: (branch: GetAllBranchResponseItem, date?: Dayjs) => {
        const businessHours = branch.businessHour;
        
        const dayOfWeek = date?.day() ?? dayjs().day();
        const businessHour = businessHours.find((o) => o.dayOfWeek === dayOfWeek && o.isDayOff === false);

        if (!businessHour) {
            return {
                openTime: dayjs().set('hour', 0).set('minute', 0),
                closeTime: dayjs().set('hour', 0).set('minute', 0)
            };
        }

        const openTime = dayjs(businessHour?.startTime);
        const closeTime = dayjs(businessHour?.endTime);

        return {openTime, closeTime};
    },
    getStartTimeBookingServiceDetail: (
        appointmentServiceDetails: AppointmentServiceCreate['appointmentServiceDetails'], 
        appointmentDate: Dayjs,
        index: number
    ) => {
        let duration = 0;

        for (let i = 0; i < index; i++) {
            duration += appointmentServiceDetails[i].service?.duration ?? 0;
        }
    
        const startTime = appointmentDate.add(duration, 'minute').toISOString();
    
        return startTime;
    },
    mapTimeAppointmentServiceDetails: (appointmentServices: AppointmentServiceCreate[], appointmentDate: string) => {
        const newAppointmentServices = appointmentServices.map(
            (apmService) => {
                const appointmentServiceDetails = apmService.appointmentServiceDetails.map(
                    (apmServiceDetail, index) => {

                        const starttime = bookingUtil.getStartTimeBookingServiceDetail(
                            apmService.appointmentServiceDetails,
                            dayjs(appointmentDate),
                            index
                        );

                        const newAppointmentServiceDetail = {
                            ...apmServiceDetail,
                            startTime: starttime,
                            endTime: dayjs(starttime).add(apmServiceDetail.service?.duration ?? 0,'minute').toISOString(),
                        };

                        return newAppointmentServiceDetail;
                    }
                );

                return {
                    ...apmService,
                    appointmentServiceDetails,
                };
            }
        );

        return newAppointmentServices;
    },
    getEmployeeIdsEachNumberOfGuest: (appointmentServices: AppointmentServiceCreate[]) => {
        let employeeIds: string[] = [];

        for (const appointmentService of appointmentServices) {
            const employeeIdsNumberOfGuest = appointmentService.appointmentServiceDetails?.map((asd) =>
                asd.appointmentServiceEmployees?.map((ase) => ase.employeeId)
            )?.flat().filter((o) => o) as string[];

            const employeeIdsUniq = [...new Set(employeeIdsNumberOfGuest)];

            employeeIds = [...employeeIds, ...employeeIdsUniq];
        }

        return employeeIds;
    },
    checkOverlapHour: (range1: [number, number], range2: [number, number]) => {
        const [start1, end1] = range1;
        const [start2, end2] = range2;

        return (start1 < end2 && end1 > start2) || (start2 < end1 && end2 > start1);
    }, 

    checkDisableTimeBlock: (
        startHourBlock: number, 
        timeOffs: GetAllTimeOffResponseItem[], 
        durationAppointment: number,
        appointmentDate?: string
    ) => {
        const isDisableTimeOff = timeOffs?.some((timeOff) => {
            const startHourTimeOff = dayjs(timeOff.disableFrom).hour() + dayjs(timeOff.disableFrom).minute() / 60;
            const endHourTimeOff = dayjs(timeOff.disableTo).hour() + dayjs(timeOff.disableTo).minute() / 60;
            const endHourBooking = startHourBlock! + durationAppointment;

            const bookingTimeRange = [startHourBlock, endHourBooking] as [number, number];
            const rangeTimeOffRange = [startHourTimeOff, endHourTimeOff] as [number, number];

            return bookingUtil.checkOverlapHour(bookingTimeRange, rangeTimeOffRange);
        });

        const currentHour = dayjs().hour() + dayjs().minute() / 60;
        const blockIsThePast = startHourBlock < currentHour && dayjs(appointmentDate).isSame(dayjs(), 'day');

        return isDisableTimeOff || blockIsThePast;
    },
    isDisableSubmit: (appointmentServices: AppointmentServiceCreate[], timeOffs: GetAllTimeOffResponseItem[], appointmentDate?: string,) => {
        const isNoService =  appointmentServices?.some((appointmentService) => {
            return appointmentService.appointmentServiceDetails.length === 0;
        });

        const startHour = dayjs(appointmentDate).hour() + dayjs(appointmentDate).minute() / 60;
        const durationAppointment = bookingUtil.getDurationHourBooking(appointmentServices, appointmentDate);

        const isDisableTimeOff = bookingUtil.checkDisableTimeBlock(startHour, timeOffs, durationAppointment, appointmentDate);

        return isNoService || !appointmentDate || isDisableTimeOff;
    },
    calcNumberService: (appointmentServices: AppointmentServiceCreate[]) => {
        return appointmentServices?.reduce((acc, appointmentService) => {
            return acc + appointmentService.appointmentServiceDetails.length;
        }, 0);
    },
    calcTotalPrice: (appointmentServices: AppointmentServiceCreate[]) => {
        return appointmentServices?.reduce((acc, appointmentService) => {
            return acc + appointmentService.appointmentServiceDetails?.reduce((acc, appointmentServiceDetail) => {
                return acc + (appointmentServiceDetail.service?.serviceBranches?.[0]?.price ?? 0);
            }, 0);
        }, 0);
    },
    ConvertDateLocalToUtcTimezone: (date: Date | string, newTimeZone?: string) => {
        const dateTimeInOldZone = dayjs(date).tz(defaultTimezone);
        const offsetOld = dayjs(date).tz(defaultTimezone).utcOffset();
        const offsetNew = dayjs(date).tz(newTimeZone).utcOffset();
        const timeDifference = offsetOld - offsetNew;

        const newTime = dateTimeInOldZone.add(timeDifference, 'minute');
        
        return newTime.toISOString();
    },

    ConvertDateToDateTimezone: (date?: Date | string, newTimeZone?: string) => {
        const result = date ? new Date(date) : new Date();

        const offsetOld = dayjs(date).tz(defaultTimezone).utcOffset();
        const offsetNew = dayjs(date).tz(newTimeZone).utcOffset();
        const timeDifference = offsetNew - offsetOld;

        result.setMinutes(result.getMinutes() + timeDifference);

        return result;
    }
};