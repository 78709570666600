import { GetAllBranchResponseItem } from '@esg/business-link-booking';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';

interface BookingLocalesProviderProps {
    children: React.ReactNode;
    branches: GetAllBranchResponseItem[] | undefined;
}

export default function BookingLocalesProvider(props: BookingLocalesProviderProps) {
    const { branches } = props;
    const { watch } = useFormContext();
    const [, setCurrentLocale] = useRecoilState(currentLocaleState);

    const currentBranch = useMemo(() => {
        const branchId = watch('branchId');
        return branches?.find((branch) => branch.id === branchId);
    }, [branches, watch]);
    
    useEffect(() => {
        if (currentBranch) {
            setCurrentLocale({
                country: currentBranch.country,
                currency: currentBranch.currency,
                timezone: currentBranch.timezone,
                language: currentBranch.languages,
                format: currentBranch.dateTimeFormat,
            });
        }
    }, [currentBranch, setCurrentLocale]);

    return props.children;
}
