import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../config';

interface GetAllProductUnitRequest extends HttpRequestData {
}

export interface BookingOnlineDateOff {
    readonly id: string;
    readonly dateOff: string;
}

export interface GetAllBookingOnlineSettingResponse {
    readonly id: string;
    readonly branchId?: string;
    readonly timeSlotDuration: number;
    readonly doubleAppointmentEnabled: boolean;
    readonly doubleAppointmentMaxSlot: number;
    readonly bookingFeatureLimit: number;
    readonly allowCustomerSelfCancel: boolean;
    readonly allowBookingSlotLimit: boolean;
    readonly bookingSlotLimit: number;
    readonly numberHoursBeforeCancel: number;
    readonly allowGroupAppointment: boolean;
    readonly requiredSelectEmployee: boolean;
    readonly showServicePrice: boolean;
    readonly confirmOnlineAppointment: boolean;
    readonly bookingOnlineDateOffs: BookingOnlineDateOff[];
}

export const getBookingOnlineSetting = httpUtil.createHttpRequestMeta<
    GetAllProductUnitRequest,
    GetAllBookingOnlineSettingResponse[]
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/:slug/settings/booking-online',
    method: 'GET',
});
