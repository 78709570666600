import {
    AppointmentServiceCreate,
    GetAllBranchResponseItem,
    GetAllTimeOffResponseItem,
} from '@esg/business-link-booking';
import { Block } from '@esg/ui';
import { useFormContext } from 'react-hook-form';
import { useMemo, useCallback } from 'react';
import { dayjs } from '@esg/shared';
import DoubleCalender from '@/components/calendar/DoubleCalender';
import TimeBlocks, { TimeBlock } from '@/components/TimeBlocks';
import Title from '@/components/Text/Title';
import { bookingUtil } from '@/utils/bookingUtils';

interface AppointmentDateFieldProps {
    timeOffs?: GetAllTimeOffResponseItem[];
    slotDurationMinute?: number;
    disableDates?: Date[];
    maxDate?: Date;
    minDate?: Date;
    currentBranch?: GetAllBranchResponseItem;
}

export default function AppointmentDateField(props: AppointmentDateFieldProps) {
    const isMobile = window.innerWidth < 768;
    const {
        timeOffs = [],
        slotDurationMinute = 15,
        maxDate,
        minDate,
        currentBranch,
    } = props;
    const { setValue, watch } = useFormContext();

    const appointmentServices = watch('appointmentServices') as AppointmentServiceCreate[];
    const appointmentDate = dayjs(watch('appointmentDate') as string);

    const startHourActive = useMemo(
        () =>
            dayjs(appointmentDate).hour() +
            dayjs(appointmentDate).minute() / 60,
        [appointmentDate]
    );

    const handleChangeAppointmentDate = useCallback(
        (date: Date) => {
            const dateTimezone = bookingUtil.ConvertDateLocalToUtcTimezone(date, currentBranch?.timezone);
            const oldDate = appointmentDate;

            const newDate = dayjs(dateTimezone)
                .set('hour', oldDate.hour())
                .set('minute', oldDate.minute());

            const newAppointmentServiceDetails = bookingUtil.mapTimeAppointmentServiceDetails(
                appointmentServices,
                newDate.toISOString()
            );

            setValue('appointmentServices', newAppointmentServiceDetails);
            setValue('appointmentDate', newDate.toISOString());
        },
        [appointmentDate, appointmentServices, currentBranch?.timezone, setValue]
    );

    const handleSelectTimeBlock = useCallback(
        (timeBlock: TimeBlock) => {
            const { startHour } = timeBlock;
            const hour = Math.floor(startHour);
            const minute = Math.round((startHour - hour) * 60);

            const newAppointmentDate = appointmentDate
                .hour(hour)
                .minute(minute);
            
            const newAppointmentServiceDetails = bookingUtil.mapTimeAppointmentServiceDetails(
                appointmentServices,
                newAppointmentDate.toISOString()
            );

            setValue('appointmentServices', newAppointmentServiceDetails);
            setValue('appointmentDate', newAppointmentDate.toISOString());
        },
        [appointmentDate, appointmentServices, setValue]
    );

    const durationAppointment = useMemo(
        () =>
            bookingUtil.getDurationHourBooking(
                appointmentServices,
                appointmentDate.toISOString()
            ),
        [appointmentServices, appointmentDate]
    );

    const timeBlocks = useMemo(() => {
        const { openTime, closeTime } = bookingUtil.getOpenCloseTimeBranch(currentBranch!, dayjs(appointmentDate));
        const startHour = openTime.hour();
        const endHour = closeTime.hour();

        const timeBlocksDefault = getTimeBlocks(startHour,endHour,slotDurationMinute);

        const timeBlocksDisable = timeBlocksDefault.map((o) => ({
            ...o,
            disable: bookingUtil.checkDisableTimeBlock(o.startHour, timeOffs, durationAppointment, appointmentDate.toISOString()),
        }));

        return timeBlocksDisable;
    }, [appointmentDate, currentBranch, durationAppointment, slotDurationMinute, timeOffs]);

    return (
        <Block className='wrapper-field'>
            <Title translateCode='Select date' />
            <DoubleCalender
                disableDates={props.disableDates}
                onChange={handleChangeAppointmentDate}
                numberTimeBlockActive={timeBlocks.filter((o) => !o.disable).length}
                isShowDoubleView={!isMobile}
                value={dayjs(appointmentDate).toDate()}
                maxDate={maxDate}
                minDate={minDate}
                timezones={currentBranch?.timezone}
            />
            <TimeBlocks
                timeBlocks={timeBlocks}
                selectTimeBlock={handleSelectTimeBlock}
                timeBlockActive={{ startHour: startHourActive }}
                isMobile={isMobile}
            />
        </Block>
    );
}

const getTimeBlocks = (
    startHour: number,
    endHour: number,
    slotDurationMinute: number
) => {
    const timeBlocks: TimeBlock[] = [];
    const slotDuration = slotDurationMinute / 60;

    while (startHour < endHour) {
        timeBlocks.push({
            startHour: startHour,
            endHour: startHour + slotDuration,
        });

        startHour += slotDuration;
    }

    return timeBlocks;
};
