import { FlexBox, IconMarket, SelectField } from '@esg/ui';
import { GetAllBranchResponseItem } from '@esg/business-link-booking';
import Title from '@/components/Text/Title';
import { translateCodes } from '@/locales';

interface SelectBranchFieldProps {
    branches?: GetAllBranchResponseItem[];
}

export default function SelectBranchField(props:SelectBranchFieldProps) {
    const {branches} = props;

    const branchesOptions = branches?.map((branch) => {
        return {
            label: (
                <FlexBox alignItems='center' gap={8}>
                    <IconMarket/> {`${branch.name} - ${branch.address}`}
                </FlexBox>
            ),
            value: branch.id,
        };
    });

    return (
        <>
            <Title translateCode={translateCodes.SELECT_BRANCH} />
            <SelectField
                name='branchId'
                options={branchesOptions}
            />
        </>
    );
}
